<template>
  <div>
    <van-nav-bar :title="$t('online_service')" :left-text="$t('back')" @click.left="$router.back()" />
    <div class="text-center py-4 px-3">
      <van-loading v-if="loading" class="justify-content-center align-content-center" color="#A5D63F" size="36"
        vertical>
        {{ $t('loading_text') }}
      </van-loading>
      <template v-else>
        <van-image v-if="qrcode_url" :src="qrcode_url" mode="aspectFill" />
        <van-empty v-else image="error" :description="error_text" />
      </template>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { getOption } from "@/api/option.service";
import { Toast, Empty } from "vant";
import { useI18n } from "vue-i18n";
export default {
  components: { [Empty.name]: Empty },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const state = reactive({
      loading: true,
      qrcode_url: "",
      error_text: t('no_customer_service'),
    });

    const loadContact = async () => {
      try {
        const { result } = await getOption('h5_contact_qrcode');
        state.qrcode_url = result.option_value;
        state.loading = false;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : t('front_end_error'), position: "bottom" });
        if (!msg) console.log(error);
        state.loading = false;
        // state.error_text = msg;
      }
    };

    onMounted(() => {
      loadContact();
    });

    return { ...toRefs(state) }
  }
}
</script>

<style lang="less" scoped></style>
